import {React,useEffect} from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function Homepage(props) {
  useEffect(() => {
    document.querySelector("html").classList.remove("signup-page");
  }, []);
  return (
    <div>
      <div className="visual">
        <div className="text-holder">
          <h1>Main Screen</h1>
          <NavLink to="/signup" className="btn btn-dark">
            Try for free
          </NavLink>
        </div>
      </div>
      <div className="center-text features">
        <div className="text-holder">
          <h2>Features</h2>
        </div>
      </div>
      <div className="center-text bg-grey how-it-works">
        <div className="text-holder">
          <h2>How it works</h2>
        </div>
      </div>
      <div className="center-text clients-say">
        <div className="text-holder">
          <h2>What our clients say</h2>
        </div>
      </div>
      <div className="center-text bg-grey pricing">
        <div className="text-holder">
          <h2>Pricing</h2>
        </div>
      </div>
      <div className="center-text footer">
        <div className="text-holder">
          <h2>Footer</h2>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
