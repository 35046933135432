import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../assets/images/logo.svg";
import { connect } from "react-redux";
import PlusIcon from "../assets/images/icons/plusicon.svg";
import InboxIcon from "../assets/images/icons/inbox.svg";
import UserImage from "../assets/images/user.png";
import { getSettings } from "../data/globalAction";

function Navigation(props) {
  const [login, setLogin] = useState([]);
  const [toggle, setToggle] = useState(false);

  const handleOverlays = () => setToggle(!toggle);
  const handleToggleClick = () => setToggle(!toggle);

  useEffect(() => {
    if (props.login && props.login.data && props.login.data.length !== 0) {
      setLogin(props.login);
    }
  }, []);
  useEffect(() => {
    console.log("navigation");
    setLogin(props.login);
  }, [props.login]);
  return (
    <div>
      {login && login.data && login.data.length !== 0 ? (
        <Navbar collapseOnSelect id="header-dashboard" expand="lg">
          <div className="logo">
            <Nav.Link as={NavLink} eventKey="1" to="/" className="p-0">
              <img src={Logo} />
            </Nav.Link>
          </div>
          <Navbar.Toggle
            onClick={handleToggleClick}
            aria-controls="basic-navbar-nav"
          />
          <div
            id="basic-navbar-nav"
            className={`${
              toggle
                ? "navbar-collapse collapse show"
                : "navbar-collapse collapse"
            }`}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <Nav>
                <Nav.Link as={NavLink} eventKey="2" to="/dashboard">
                  Dashboard
                </Nav.Link>
                <Nav.Link as={NavLink} eventKey="3" to="/calculations">
                  Calculations
                </Nav.Link>
                <Nav.Link as={NavLink} eventKey="3" to="/invoices">
                  Invoices
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  className="btn-primary"
                  eventKey="4"
                  to="/calculator"
                >
                  <div className="icon">
                    <img src={PlusIcon} alt="Icon Image" />
                  </div>{" "}
                  Calculator
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  className="mob-nav"
                  eventKey="1"
                  to="/settings"
                >
                  Settings
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link href="/logout" className="mob-nav">
                  Logout
                </Nav.Link>
              </Nav>
              <Nav className="d-flex align-items-center drop-right">
                <Nav.Link
                  className="with-icon inbox"
                  as={NavLink}
                  eventKey="5"
                  to="/dashboard"
                >
                  <div className="icon">
                    <img src={InboxIcon} alt="Icon Image" />
                  </div>
                  <span className="msg-count">4</span>
                  <div className="inbox-text">Inbox</div>
                </Nav.Link>
                <div className="d-flex align-items-center">
                  <NavDropdown
                    className="nav-option"
                    title={
                      <div className="d-flex align-items-center">
                        {login.data &&
                        login.data.data &&
                        login.data.data.image ? (
                          <div className="user-pic">
                            <img src={login.data.data.image} alt="Image" />
                          </div>
                        ) : (
                          <div className="name-char">
                            {login.data.data.first_name
                              .charAt(0)
                              .toUpperCase() +
                              login.data.data.last_name.charAt(0).toUpperCase()}
                          </div>
                        )}
                        <div className="d-flex flex-column">
                          {login.data.data.first_name +
                            " " +
                            login.data.data.last_name}
                          {login.data.data.user_role ? (
                            <small>{login.data.data.user_role}</small>
                          ) : null}
                        </div>
                      </div>
                    }
                  >
                    <NavDropdown.Item as={NavLink} eventKey="1" to="/settings">
                      Settings
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Nav>
            </div>
          </div>
        </Navbar>
      ) : (
        <Navbar collapseOnSelect id="header" expand="lg" fixed="top">
          <div className="logo">
            <Nav.Link as={NavLink} eventKey="1" to="/">
              <img src={Logo} />
            </Nav.Link>
          </div>
          <Navbar.Toggle
            onClick={handleToggleClick}
            aria-controls="basic-navbar-nav"
          />
          <div
            id="basic-navbar-nav"
            className={`${
              toggle
                ? "navbar-collapse collapse show"
                : "navbar-collapse collapse"
            }`}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <Nav>
                <Nav.Link as={NavLink} eventKey="2" to="/platform">
                  Platform
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  eventKey="3"
                  to="/pricing"
                  className="ml-4"
                >
                  Pricing
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  eventKey="4"
                  to="/contact"
                  className="ml-4"
                >
                  Contacts
                </Nav.Link>
              </Nav>
              <Nav className="d-flex align-items-center">
                <Nav.Link as={NavLink} eventKey="5" to="/signin">
                  Sign in
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  eventKey="6"
                  className="btn btn-outline-dark ml-3 px-4"
                  to="/signup"
                >
                  Try for Free
                </Nav.Link>
              </Nav>
            </div>
          </div>
        </Navbar>
      )}
      <div
        className={`overlay ${toggle ? "active" : ""}`}
        onClick={handleOverlays}
      ></div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
