export const signup_complete_message =
  "Signup completed please try login. Thanks!";
export const settings_billing_trail_days = "days remaining in trial";
export const settings_billing_trail_days_expire = "Trial days expired";
export const employees_options = [
  { label: "Less than 10", value: "<10" },
  { label: "More than 10", value: "10+" },
  { label: "More than 50", value: "50+" },
  { label: "More than 100", value: "100+" },
  { label: "More than 200", value: "200+" },
  { label: "More than 300", value: "300+" },
  { label: "More than 400", value: "40+" },
];
export const default_county = { label: "United States", value: 1 };
export const default_role = { label: "Admin", value: 2 };
export const update_message = "Update Successfully";
export const logout_message = "You need to login with new email again!";
export const default_tax_type = ["Total Invoice", "Gross Profit"];
export const fetching_data = "Fetching data please wait ....";
export const no_data = "There are no records to display";
export const err_billing_informeation = "Please add payment method";
export const unauthorized = "You Are Not Authorized";
