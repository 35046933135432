import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Form,
  FormControl,
  Container,
  ProgressBar,
  Tabs,
  Tab,
  Row,
  Col,
  Card,
  Alert,
} from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { RiCloseLine } from "react-icons/ri";
import {
  getSettings,
  getTeams,
  saveOrganizationTeamAttempt,
  updateOrganizationTeamAttempt,
  getRolesAttempt,
  setTeamLogo,
} from "./data/globalAction";
import DataTable from "react-data-table-component";
import * as Constants from "../core/Constants";
import SimpleReactValidator from "simple-react-validator";
import TagsInput from "react-tagsinput";
import MusicImage from "./assets/images/music-band.png";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import Select from "react-select";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useHistory } from "react-router-dom";
import AfterLogin from "./middleware/AfterLogin";
import { RiArrowUpSLine } from "react-icons/ri";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import GraphIcon from "../component/assets/images/icons/graph-icon.svg";
import PersentIcon from "../component/assets/images/icons/persent-icon.svg";
import { isAdmin, isOwner } from "../core/UserRoleCheck";

const customRoleControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    minWidth: 100,
    background: state.isFocused ? "transparent" : "transparent",
    borderWidth: 1,
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    fontSize: "12px",
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 44,
    marginBottom: "15px",
    background: state.isFocused ? "#fff" : "#f1f3f6",
    borderWidth: 1,
    borderColor: state.isFocused ? "#f1f3f6" : "#fff",
    boxShadow: state.isFocused ? null : null,
    fontSize: "13px",
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
const customGraphControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    marginBottom: "15px",
    background: "#fff",
    borderRadius: "8px",
    borderWidth: 1,
    borderColor: state.isFocused ? "#bbc0d3" : "#E5E8F5",
    boxShadow: "none",
    fontSize: "13px",
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};
const customTableStyles = {
  header: {
    style: {
      padding: "0",
      minHeight: "inherit",
    },
  },
  headRow: {
    style: {
      color: "#43536D",
      borderColor: "#E5E8F5",
      borderStyle: "solid",
      borderWidth: "1px",
      borderLeftWidth: "0",
      borderRightWidth: "0",
      backgroundColor: "#FCFCFF",
    },
  },
  headCells: {
    style: {
      fontSize: "13px",
      fontWeight: "normal",
      color: "#767778",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  rows: {
    style: {
      minHeight: "65px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "#E5E8F5",
      },
    },
  },
  cells: {
    style: {
      minHeight: "65px",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#fff",
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E5E8F5",
    },
  },
};

const CustomTooltip = ({ payload, active }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div>
          <span>Revenue </span>
          {`$ ${payload[0].value.toLocaleString()}`}
        </div>
        <div>
          <span>Gross Profit</span>
          {`$ ${
            (parseInt(payload[0].value) * parseInt(10)) / parseInt(100)
          } (10%)`}
        </div>
      </div>
    );
  }

  return null;
};
function Dashboard(props) {
  const [isShowing, setIsShowing] = useState(false);
  const [roles, setRoles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showModalTeam, setShowMotalTeam] = useState(false);
  const handleCloseTeam = () => setShowMotalTeam(false);
  const [key, setKey] = useState("actual-revenue");
  const [settings, setSettings] = useState([]);
  const history = useHistory();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      team_name: "",
      emails: [],
    }
  );
  const [inputList, setInputList] = useState([]);
  const [validator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [teams, setTeams] = useState([]);
  const [rowTeam, setRowTeam] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [teamLogoApplied, setTeamLogoApplied] = useState(false);
  const [logoData, setLogoData] = useState({});

  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const [invitationError, setInvitationError] = useState([]);
  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row && row.info && row.info.image ? (
            <div className="user-pic">
              <img src={row.info.image} alt="Image" />
            </div>
          ) : (
            <div className="team-name-short mr-2">
              {row.name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)}
            </div>
          )}
          {row.name}
        </div>
      ),
    },
    {
      name: "Members",
      selector: "members",
      sortable: true,
    },
    {
      name: "Calcs",
      selector: "calcs",
      sortable: true,
    },
    {
      name: "Invoices",
      selector: "invoices",
      sortable: true,
    },
    {
      name: "Revenue",
      selector: "revenue",
      sortable: true,
    },
    {
      name: "Gross Profit",
      selector: "grossprofit",
      sortable: true,
    },
    {
      name: "Team performance",
      selector: "teamperformance",
      cell: (row) => (
        <div className="d-flex align-items-center w-100">
          <div className="persent mr-3">{row.teamperformance}%</div>
          <ProgressBar now={row.teamperformance} />
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <Button
          variant="outline-light"
          size="sm"
          onClick={() => handleShow("manage_team", row)}
        >
          Manage Team
        </Button>
      ),
    },
  ];
  let rolesOptions = [];
  {
    roles &&
      roles.map((role) =>
        rolesOptions.push({ label: role.name, value: role.id })
      );
  }
  const setIsShowingClose = () => {
    localStorage.setItem("isShowing", false);
    setIsShowing(false);
  };
  const handleRemoveClick = (index) => {
    if (rowTeam === 0) {
      const list = [...inputList];
      console.log(list, index);
      list.splice(index, 1);
      console.log(list, index);
      setInputList(list);
    } else {
      const list = [...teamMembers];
      list.splice(index, 1);
      setTeamMembers(list);
    }
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  const handleTags = (emails) => {
    console.log("emails", emails);
    let emailErrors = [];
    emails.forEach((email, i) => {
      emails[i] = email.trim();
      if (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        setInputList([...inputList, { email: email, role: rolesOptions[0] }]);
      } else {
        emailErrors.push(
          "You have entered '" + email + "' an invalid email address!"
        );
      }
    });
    if (emailErrors.length === 0) {
      setInvitationError([]);
      setFormInput({ ["emails"]: [] });
      if (rowTeam !== 0) {
        const list = [...teamMembers];
        list.push({
          team_id: rowTeam,
          user_id: 0,
          team_user_role: 2,
          email: emails[0],
          team_name: "",
          user_name: "",
          user_image: "",
        });
        setTeamMembers(list);
      }
    } else {
      setInvitationError(emailErrors);
    }

    console.log("emails", emails);
  };

  useEffect(() => {
    if (props.login && props.login.headers) {
      localStorage.setItem("headers", JSON.stringify(props.login.headers));
      async function fetchSettings() {
        setSettings(await getSettings());
      }
      fetchSettings();
      async function fetchTeams() {
        let team = await getTeams();
        if (
          team &&
          team.data &&
          team.data.organization_teams &&
          team.data.organization_teams.length !== 0
        ) {
          setTeams(team.data.organization_teams);
        }
      }
      fetchTeams();
      if (
        props.login.data.data.login_count < 2 &&
        (localStorage.getItem("isShowing") === true ||
          localStorage.getItem("isShowing") === null)
      ) {
        setIsShowing(true);
        localStorage.setItem("isShowing", true);
      }
    }
    async function fetchRoles() {
      setRoles(await getRolesAttempt());
    }
    fetchRoles();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("expire")) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  });
  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  }, [settings]);

  const options = {
    chart: {
      height: 320,
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      month: "%e. %b",
      year: "%b",
      tickWidth: 0,
      lineWidth: 0,
      crosshair: {
        width: 2,
        color: "#7848FF",
        dashStyle: "shortdot",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    yAxis: {
      gridLineColor: "#E5E8F5",
      gridLineDashStyle: "longdash",
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#43536D",
          textTransform: "uppercase",
          fontWeight: "300",
          fontFamily: "Formular",
        },
      },
    },
    tooltip: {
      shadow: false,
      backgroundColor: "none",
      borderColor: "none",
      padding: 0,
      borderRadius: "8px",
      className: "chart-tooltip",
      useHTML: true,
      formatter: function () {
        return (
          "<div class='custom-graph-tooltip'><div class='px-3 py-2 d-flex'><div><span>Revenue</span>$" +
          this.y +
          "</div></div><div class='px-3 py-2 d-flex align-items-end'><div><span>Gross Profit</span>$" +
          this.y / 10 +
          "</div><small class='percent-up ml-3'>10%</small></div></div>"
        );
      },
    },
    noData: {
      attr: null,
      position: { x: 0, y: 0, align: "center", verticalAlign: "middle" },
      style: { fontSize: "12px", fontWeight: "bold", color: "#60606a" },
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: "#7848FF",
          shadow: false,
          lineWidth: 2,
          width: 30,
          height: 30,
          lineColor: "#fff",
          enabled: false,
        },
      },
    },
    series: [
      {
        showInLegend: false,
        type: "area",
        lineWidth: 2,
        lineColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#e80556"], // start
            [0.5, "#95009a"], // middle
            [1, "#6c00dd"], // end
          ],
        },
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "rgba(149, 0, 154, 0.2)"], // start
            [0.5, "rgba(149, 0, 154, 0.03)"], // middle
            [1, "rgba(255, 255, 255, 0.01)"], // end
          ],
        },
        data: [
          {
            x: Date.UTC(2018, 4, 1),
            y: 300000,
          },
          {
            x: Date.UTC(2018, 4, 2),
            y: 350000,
          },
          {
            x: Date.UTC(2018, 4, 3),
            y: 300000,
          },
          {
            x: Date.UTC(2018, 4, 4),
            y: 450000,
          },
          {
            x: Date.UTC(2018, 4, 5),
            y: 320000,
          },
          {
            x: Date.UTC(2018, 4, 6),
            y: 440000,
          },
          {
            x: Date.UTC(2018, 4, 7),
            y: 380000,
          },
          {
            x: Date.UTC(2018, 4, 8),
            y: 480000,
          },
          {
            x: Date.UTC(2018, 4, 9),
            y: 310000,
          },
          {
            x: Date.UTC(2018, 4, 10),
            y: 400000,
          },
          {
            x: Date.UTC(2018, 4, 11),
            y: 310000,
          },
          {
            x: Date.UTC(2018, 4, 12),
            y: 290000,
          },
        ],
      },
    ],
  };
  const handleShow = async (index, row = "") => {
    if (index === "create_team") {
      setInputList([]);
      setRowTeam(0);
      setFormInput({ ["emails"]: [] });
      setFormInput({ ["team_name"]: "" });
      setShowMotalTeam(true);
    } else if (index === "manage_team") {
      setLogoData({});
      setTeamLogoApplied(false);
      let users = [];
      row.info.organization_team_user.forEach((element) => {
        // console.log("element", row.info, element);
        let user_name = "";
        if (
          element.user.first_name !== null ||
          element.user.last_name !== null
        ) {
          user_name = element.user.first_name + " " + element.user.last_name;
        }
        users.push({
          team_id: row.info.id,
          user_id: element.user.id,
          team_user_role: element.role_id,
          email: element.user.email,
          team_name: row.info.name,
          user_name: user_name,
          user_image: element.user.image,
        });
      });
      console.log("team", users);
      setRowTeam(row.info.id);
      setInputList([]);
      setFormInput({ ["emails"]: [] });
      setFormInput({ ["team_name"]: row.info.name });
      setTeamMembers(users);
      setShowMotalTeam(true);
    } else if (index === "add_team") {
      var valid = 1;
      setDisabled(true);
      if (!validator.fieldValid("team_name")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let invitationList = inputList.map((x, i) => {
          return { email: x.email, role: x.role.value };
        });
        let data = {
          data: {
            team_name: formInput.team_name,
            invitation: invitationList,
          },
        };

        let teamRequst = await saveOrganizationTeamAttempt(
          JSON.stringify(data)
        );
        if (teamRequst.status === 200) {
          let imageResult = { status: 400 };
          if (teamLogoApplied) {
            let teamLogoForm = new FormData();
            teamLogoForm.append("team_id", logoData.team_id);
            teamLogoForm.append("image", logoData.image);
            imageResult = await setTeamLogo(teamLogoForm);
          }
          if (imageResult.status === 200) {
            setTeams(imageResult.data.organization_teams);
          } else {
            setTeams(teamRequst.data.organization_teams);
          }
          setDisabled(false);
          setShowMotalTeam(false);
          setTeamLogoApplied(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
      }
    } else if (index === "update_team") {
      var valid = 1;
      setDisabled(true);
      if (!validator.fieldValid("team_name")) {
        valid = 0;
      }
      if (!valid) {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      } else {
        let invitationList = teamMembers.map((x, i) => {
          return { email: x.email, role: x.team_user_role };
        });
        let data = {
          data: {
            team_id: rowTeam,
            team_name: formInput.team_name,
            invitation: invitationList,
          },
        };

        let teamRequst = await updateOrganizationTeamAttempt(
          JSON.stringify(data)
        );
        if (teamRequst.status === 200) {
          let imageResult = { status: 400 };
          if (teamLogoApplied) {
            console.log(logoData);
            let teamLogoForm = new FormData();
            teamLogoForm.append("team_id", logoData.team_id);
            teamLogoForm.append("image", logoData.image);
            imageResult = await setTeamLogo(teamLogoForm);
          }
          if (imageResult.status === 200) {
            setTeams(imageResult.data.organization_teams);
          } else {
            setTeams(teamRequst.data.organization_teams);
          }
          setDisabled(false);
          setShowMotalTeam(false);
          setTeamLogoApplied(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
        }
      }
    }
    console.log("index", index);
  };
  const handleInputChange = (e, index, opt = "") => {
    let name, value;

    name = opt;
    value = e;

    if (rowTeam === 0) {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    } else {
      const list = [...teamMembers];
      console.log("list", value.value, list[index]["team_user_role"]);
      list[index]["team_user_role"] = value.value;
      setTeamMembers(list);
    }
  };
  const handleTeamLogo = async (e) => {
    setLogoData({
      team_id: rowTeam,
      image: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    });
    console.log("logoData=> ", logoData);
    setTeamLogoApplied(true);
  };
  const renderCreateTeam = () => {
    let ErrorlistItems = "";
    if (invitationError) {
      let errors = invitationError;
      ErrorlistItems = errors.map((err) => (
        <Alert variant="danger">{err}</Alert>
      ));
    }
    var initials = formInput.team_name
      .replace(/[^a-zA-Z- ]/g, "")
      .match(/\b\w/g);

    let selectedTeam;
    if (teams) {
      selectedTeam = teams.filter((t) => t.info.id === rowTeam);
    }
    // console.log("rowTeam => ", rowTeam)
    // console.log("selected team => ", selectedTeam)

    return (
      <Modal
        animation={false}
        className="add-states-modal create-team-modal"
        size="md"
        centered
        show={showModalTeam}
      >
        <Modal.Header className="with-title normal">
          <Modal.Title>
            {rowTeam === 0 ? "Create Team" : "Manage Members"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-1 pb-3">
          <div className="team-name">
            <label htmlFor="team_logo">
              {teamLogoApplied ? (
                <div className="user-pic">
                  <img src={logoData.url} alt="Image" />
                </div>
              ) : rowTeam > 0 &&
                teams &&
                teams.length > 0 &&
                selectedTeam[0] &&
                selectedTeam[0].info.image ? (
                <div className="user-pic">
                  <img src={selectedTeam[0].info.image} alt="Image" />
                </div>
              ) : (
                <div className="team-name-short">
                  {formInput.team_name ? initials.join("") : "TN"}
                </div>
              )}
            </label>
            {formInput.team_name ? formInput.team_name : "Team Name"}
          </div>
          <input
            type="file"
            id="team_logo"
            style={{ display: "none" }}
            onChange={handleTeamLogo}
          />
          <div className="team-name-field">
            <Form.Group>
              <FormControl
                id="team_name"
                name="team_name"
                placeholder="Team name"
                value={formInput.team_name}
                onChange={(e) => handleInput(e)}
              />
              {validator.message("team_name", formInput.team_name, "required", {
                className: "text-danger",
              })}
            </Form.Group>
          </div>
          <div className="invite-teamates">
            <div className="invite-title">Invite teammates</div>

            <TagsInput
              value={formInput.emails}
              onChange={handleTags}
              inputProps={{
                className: "react-tagsinput-input w-100",
                placeholder: "Add team members by name or email...",
              }}
            />
            {invitationError ? <div>{ErrorlistItems}</div> : null}
          </div>
          <div className="added-team-members">
            <div className="added-members-title">Team members</div>
            <div className="added-members-lists">
              {rowTeam === 0
                ? inputList.map((x, i) => {
                    return (
                      <div className="member-list d-flex justify-content-between">
                        <div className="member-name">
                          <div>
                            <span>{x.email}</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <Select
                            className="custommargin"
                            options={rolesOptions}
                            styles={customRoleControlStyles}
                            value={x.role}
                            name="role"
                            onChange={(e) => {
                              handleInputChange(e, i, "role");
                            }}
                          />
                          <Button
                            className="custommargin btn-icon"
                            variant="secondary"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <RiCloseLine />
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : teamMembers.map((x, i) => {
                    console.log("x", x);
                    return (
                      <div className="member-list d-flex justify-content-between">
                        <div className="member-name">
                          {x.user_name ? (
                            <div className="member-name-short">
                              {x.user_name
                                .replace(/[^a-zA-Z- ]/g, "")
                                .match(/\b\w/g)}
                            </div>
                          ) : null}
                          <div>
                            {x.user_name ? (
                              <strong>{x.user_name}</strong>
                            ) : null}
                            <span>{x.email}</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <Select
                            className="custommargin"
                            options={rolesOptions}
                            styles={customRoleControlStyles}
                            value={rolesOptions.find(
                              ({ value }) => value === x.team_user_role
                            )}
                            name="role"
                            onChange={(e) => {
                              handleInputChange(e, i, "role");
                            }}
                          />
                          <Button
                            className="custommargin btn-icon"
                            variant="secondary"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <RiCloseLine />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={handleCloseTeam}>
            Cancel
          </Button>
          {rowTeam === 0 ? (
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => handleShow("add_team")}
            >
              Done
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => handleShow("update_team")}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      {localStorage.getItem("expire") !== null &&
      localStorage.getItem("expire") === true ? (
        <Payment isShowing={true} />
      ) : null}

      <Modal
        animation={false}
        className="import-states-modal trial-modal"
        size="lg"
        centered
        show={isShowing}
        backdrop="static"
      >
        <Modal.Body>
          <div className="details">
            <div className="icon">
              <img src={MusicImage} alt="Icon Image" />
            </div>
            <div className="title">Your 7-day trial starts today</div>
            <p>
              You’re in! Your trial gives you <br /> access to all ReCalc
              features.
            </p>
            <p>
              Once your trial ends, you will need to pay for the selected plan
            </p>
          </div>
          <div className="button-holder d-flex align-items-center flex-column">
            <Button variant="primary" onClick={setIsShowingClose}>
              Get started
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="dashboard-main pb-5">
        <div className="company-info mb-4">
          <Container>
            {settings.data &&
            settings.data.organization_users &&
            settings.data.organization_users.length !== 0 &&
            settings.data.organization_users[0].organization ? (
              <div className="company-name">
                {settings &&
                settings.data.organization_users[0].organization.image ? (
                  <div className="user-pic">
                    <img
                      src={
                        settings.data.organization_users[0].organization.image
                      }
                      alt="Image"
                    />
                  </div>
                ) : (
                  <div className="company-name-short">
                    {settings.data.organization_users[0].organization.name
                      .replace(/[^a-zA-Z- ]/g, "")
                      .match(/\b\w/g)}
                  </div>
                )}
                <div>
                  {settings.data.organization_users[0].organization.name}
                  <small className="text-primary"></small>
                </div>
              </div>
            ) : null}
            <div className="company-revenue-tabs">
              <Card>
                <Card.Body>
                  <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="actual-revenue" title="Actual Revenue">
                      <Row>
                        <Col lg={4}>
                          <div className="graph-box-holder border d-flex flex-column h-100">
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image primary-icon">
                                <img src={BadgeIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Revenue</span>
                                <div className="g-value">
                                  <span className="text-success mr-1">$</span>
                                  1,000,000
                                </div>
                              </div>
                            </div>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image info-icon">
                                <img src={GraphIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Gross Profit</span>
                                <div className="g-value">
                                  <span className="text-success mr-1">$</span>
                                  1,000,000
                                </div>
                              </div>
                            </div>
                            <div className="graph-box d-flex align-items-center">
                              <div className="icon-image success-icon">
                                <img src={PersentIcon} alt="Icon" />
                              </div>
                              <div>
                                <span className="title">Gross Profit %</span>
                                <div className="g-value">
                                  +10<span className="text-info">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="filter-graph">
                            <Select
                              styles={customGraphControlStyles}
                              placeholder="Last Month"
                              isSearchable={false}
                            />
                          </div>
                          <div className="graph-box h-100">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="projected-form" title="Projected Revenue">
                      <Row>
                        <Col lg={4}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image primary-icon">
                              <img src={BadgeIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Revenue</span>
                              <div className="g-value">
                                <span className="text-success mr-1">$</span>
                                1,000,000
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image info-icon">
                              <img src={GraphIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Gross Profit</span>
                              <div className="g-value">
                                <span className="text-success mr-1">$</span>
                                1,000,000
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="graph-box d-flex align-items-center">
                            <div className="icon-image success-icon">
                              <img src={PersentIcon} alt="Icon" />
                            </div>
                            <div>
                              <span className="title">Gross Profit %</span>
                              <div className="g-value">
                                +10<span className="text-info">%</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </div>
          </Container>
        </div>

        {isAdmin || isOwner ? (
          <Container>
            {renderCreateTeam()}
            <div className="datatable-box">
              <div className="datatable-holder">
                <div className="team-list py-3 px-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-block team-info">
                      {" "}
                      {teams && teams.length ? teams.length : "0"} Teams
                    </div>
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => handleShow("create_team")}
                    >
                      <BsPlus /> Create Team
                    </Button>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={teams}
                  customStyles={customTableStyles}
                  responsive
                  fixedHeader={true}
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 15, 25, 50]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Records per page:",
                    rangeSeparatorText: "out of",
                  }}
                />
              </div>
            </div>
          </Container>
        ) : null}
      </div>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
      <AfterLogin />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
