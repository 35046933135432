import React, { useState } from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  top: {
    margin: theme.spacing(2, 0, 0),
  },
}));
export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();

    props.setDisabled(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    props.verifyCard(error, paymentMethod);
  };

  return (
    <div className={classes.top}>
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              marginTop: "20px",
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
      <DialogActions>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={props.disabled}
        >
          {props.buttonText}
        </Button>
      </DialogActions>
    </div>
  );
};
