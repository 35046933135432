import React, { useState, useEffect, useReducer } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
  Form,
  FormControl,
} from "react-bootstrap";
import { HiCheck } from "react-icons/hi";
import { useHistory, Link } from "react-router-dom";

import {
  getBillSettings,
  getPlans,
  updateSubscription,
  createSubscription,
  simpleSubscription,
  getSettings,
} from "./data/globalAction";
import Moment from "react-moment";
import { toast } from "react-toastify";
import * as Constants from "../core/Constants";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AfterLogin from "./middleware/AfterLogin";

function ChangePlan() {
  const [settings, setSettings] = useState([]);

  const history = useHistory();
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PKEY)
  );
  const [cardInfo, setCardInfo] = useState([]);
  const [tab, setTab] = useState("");
  const [planDuration, setPlanDuration] = useState(true);
  const handleChangePlansType = () => {
    setPlanDuration(!planDuration);
  };
  const [billSettings, setBillSettings] = useState([]);
  const [plans, setPlans] = useState([]);
  const [rowPlan, setRowPlans] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      promocode: "",
      pm_id: "",
      plan_members: 1,
    }
  );
  useEffect(() => {
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();
    async function fetchPlans() {
      setPlans(await getPlans());
    }
    fetchPlans();
  }, []);
  useEffect(() => {
    if (
      billSettings &&
      billSettings.subscription &&
      billSettings.subscription.cancel_at !== null
    ) {
      setBtnDisabled(true);
    }
  }, [billSettings]);

  const addition = () => {
    setFormInput({ ["plan_members"]: formInput.plan_members + 1 });
  };
  const subtraction = () => {
    if (formInput.plan_members - 1 > 0)
      setFormInput({ ["plan_members"]: formInput.plan_members - 1 });
  };
  const verifyCard = async (error, info) => {
    if (error && error.message) {
      toast.error(error.message);
      setDisabled(false);
    } else {
      const name = "pm_id";
      const newValue = info.id;
      setFormInput({ [name]: newValue });
      let data = {
        pm_id: info.id,
        plan_id: rowPlan.stripe_id,
        plan_members: formInput.plan_members,
      };
      let result = await simpleSubscription(JSON.stringify(data));
      if (result.status === 200) {
        toast.success(Constants.update_message);
        history.push("/settings/billing-payment");
      }
      setDisabled(false);
    }
  };
  const handleConfirmPlan = async () => {
    setDisabled(true);
    let data = {
      plan_id: rowPlan.stripe_id,
      option: "update_plan",
      promocode: formInput.promocode,
    };

    let result = await updateSubscription(JSON.stringify(data));
    if (result.status === 200) {
      toast.success(Constants.update_message);
      history.push("/settings/billing-payment");
    }

    setDisabled(false);
  };
  const handleSelectPlan = async (plan, index) => {
    setRowPlans(plan);
    setTab(index);
    console.log("plan", plan);
  };

  const handleConfirmWithPaymentMethod = async () => {
    setConfirmDisabled(true);
    let data = {
      plan_id: rowPlan.stripe_id,
      plan_members: formInput.plan_members,
    };

    let result = await createSubscription(JSON.stringify(data));
    if (result.status === 200) {
      toast.success(Constants.update_message);
      history.push("/settings/billing-payment");
    }

    setConfirmDisabled(false);
  };
  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  var title = "Choose Plan";
  if (billSettings && billSettings.plan) {
    title = "Change Plan";
  }
  let plans_list = "";

  if (plans && plans.length > 0) {
    plans_list = (
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between mb-2">
                <div className="plan-title">
                  <strong>{plans[0].name}</strong>
                </div>
                {planDuration ? (
                  <div className="price">
                    {plans[0].amount}
                    <span className="text-success">$</span>
                    <small>/mo.</small>
                  </div>
                ) : (
                  <div className="price">
                    <u>{plans[0].amount}$</u> {plans[3].amount / 12}
                    <span className="text-success">$</span>
                    <small>/mo.</small>
                  </div>
                )}
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              {planDuration ? (
                billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[0].id ? (
                  <Button variant="outline-dark" block className="mb-3">
                    <HiCheck /> Actual plan
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleSelectPlan(plans[0], "select_plan")}
                    variant="primary"
                    block
                    className="mb-3"
                    disabled={btnDisabled}
                  >
                    Select plan
                  </Button>
                )
              ) : billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[3].id ? (
                <Button variant="outline-dark" block className="mb-3">
                  <HiCheck /> Actual plan
                </Button>
              ) : (
                <Button
                  onClick={() => handleSelectPlan(plans[3], "select_plan")}
                  variant="primary"
                  block
                  className="mb-3"
                  disabled={btnDisabled}
                >
                  Select plan
                </Button>
              )}
              <ul className="list-unstyled m-0">
                <li>Social Security Calculation</li>
                <li>Medicare Calculation</li>
                <li>FUTA (Federal Unemployment) Calculation</li>
                <li>SUTA (State Unemployment) Calculation</li>
                <li>Workers Compensation Calculation</li>
                <li>Wage Cap Calculations</li>
                <li>Multiple Currencies</li>
                <li>Overtime Calculation</li>
                <li>Save and title up to 100 calculations</li>
                <li>Single Country</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between mb-2">
                <div className="plan-title">
                  <strong>{plans[1].name}</strong>
                </div>
                {planDuration ? (
                  <div className="price">
                    {plans[1].amount}
                    <span className="text-success">$</span>
                    <small>/mo.</small>
                  </div>
                ) : (
                  <div className="price">
                    <u>{plans[1].amount}$</u> {plans[4].amount / 12}
                    <span className="text-success">$</span>
                    <small>/mo.</small>
                  </div>
                )}
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

              {planDuration ? (
                billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[1].id ? (
                  <Button variant="outline-dark" block className="mb-3">
                    <HiCheck /> Actual plan
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleSelectPlan(plans[1], "select_plan")}
                    variant="primary"
                    block
                    className="mb-3"
                    disabled={btnDisabled}
                  >
                    Select plan
                  </Button>
                )
              ) : billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[4].id ? (
                <Button variant="outline-dark" block className="mb-3">
                  <HiCheck /> Actual plan
                </Button>
              ) : (
                <Button
                  onClick={() => handleSelectPlan(plans[4], "select_plan")}
                  variant="primary"
                  block
                  className="mb-3"
                  disabled={btnDisabled}
                >
                  Select plan
                </Button>
              )}

              <ul className="list-unstyled m-0">
                <li>Social Security Calculation</li>
                <li>Medicare Calculation</li>
                <li>FUTA (Federal Unemployment) Calculation</li>
                <li>SUTA (State Unemployment) Calculation</li>
                <li>Workers Compensation Calculation</li>
                <li>Wage Cap Calculations</li>
                <li>Multiple Currencies</li>
                <li>Overtime Calculation</li>
                <li>Healthcare Plans</li>
                <li>Additional Benefit Cost calculation</li>
                <li>Screening Costs calculation</li>
                <li>Financing cost calculation</li>
                <li>MSP/VMS cost calculation</li>
                <li>Multiple State View</li>
                <li>Statutory PTO calculation</li>
                <li>GSA Data</li>
                <li>Stipends calculation</li>
                <li>Pay package creation</li>
                <li>Unlimited title and saves</li>
                <li>Gross Profit breakdown by pay period</li>
                <li>Sales Tax Rules</li>
                <li>API integrations</li>
                <li>Multiple Countries</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between">
                <div className="plan-title">
                  <strong>{plans[2].name}</strong>
                </div>
                {planDuration ? (
                  <div className="price">
                    {plans[2].amount}
                    <span className="text-success">$</span>
                    <small>/mo.</small>
                  </div>
                ) : (
                  <div className="price">
                    <u>{plans[2].amount}$</u> {plans[5].amount / 12}
                    <span className="text-success">$</span>
                    <small>/mo.</small>
                  </div>
                )}
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

              {planDuration ? (
                billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[2].id ? (
                  <Button variant="outline-dark" block className="mb-3">
                    <HiCheck /> Monthly plan
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleSelectPlan(plans[2], "select_plan")}
                    variant="primary"
                    block
                    className="mb-3"
                    disabled={btnDisabled}
                  >
                    Select plan
                  </Button>
                )
              ) : billSettings &&
                billSettings.plan &&
                billSettings.plan.id === plans[5].id ? (
                <Button variant="outline-dark" block className="mb-3">
                  <HiCheck /> Actual plan
                </Button>
              ) : (
                <Button
                  onClick={() => handleSelectPlan(plans[5], "select_plan")}
                  variant="primary"
                  block
                  className="mb-3"
                  disabled={btnDisabled}
                >
                  Select plan
                </Button>
              )}

              <ul className="list-unstyled m-0">
                <li>Social Security Calculation</li>
                <li>Medicare Calculation</li>
                <li>FUTA (Federal Unemployment) Calculation</li>
                <li>SUTA (State Unemployment) Calculation</li>
                <li>Workers Compensation Calculation</li>
                <li>Wage Cap Calculations</li>
                <li>Multiple Currencies</li>
                <li>Overtime Calculation</li>
                <li>Healthcare Plans</li>
                <li>Additional Benefit Cost calculation</li>
                <li>Screening Costs calculation</li>
                <li>Financing cost calculation</li>
                <li>MSP/VMS cost calculation</li>
                <li>Multiple State View</li>
                <li>Statutory PTO calculation</li>
                <li>GSA Data</li>
                <li>Stipends calculation</li>
                <li>Pay package creation</li>
                <li>Unlimited title and saves</li>
                <li>Gross Profit breakdown by pay period</li>
                <li>Sales Tax Rules</li>
                <li>Create customer profiles</li>
                <li>Group Pricing into folders - organize and catalog</li>
                <li>Request for pricing forms and inbox</li>
                <li>Teamwork pricing - live colaberate</li>
                <li>Manager View</li>
                <li>Approval Settings</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
  function getTabContent() {
    switch (tab) {
      case "select_plan":
        return (
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={6}>
                <div className="confirm-plan">
                  <h2 className="title">
                    Confirm <br /> new plan selection
                  </h2>
                  {billSettings && billSettings.plan && billSettings.plan.id ? (
                    <Card>
                      <Card.Body className="p-4">
                        <div className="plan-header">
                          <Badge variant="success">New plan</Badge>
                          <h4>{rowPlan.name}</h4>
                          <div className="d-flex justify-content-between">
                            {billSettings && billSettings.subscription ? (
                              <p>
                                The amount you will pay monthly starting from{" "}
                                <Moment format="MMMM DD, YYYY">
                                  {billSettings.subscription.end_date}
                                </Moment>
                              </p>
                            ) : null}

                            <div className="price">
                              {rowPlan.amount.toFixed(2)}{" "}
                              <span className="text-success mr-1">$</span>
                              <small>
                                for{" "}
                                {rowPlan.frequency === "2" ? "year" : "month"}
                              </small>
                            </div>
                          </div>
                        </div>
                        <Form.Group>
                          <Form.Label>Add promocode</Form.Label>
                          <FormControl
                            id="promocode"
                            name="promocode"
                            value={formInput.promocode}
                            onChange={handleInput}
                          />
                        </Form.Group>
                        <div className="card-bottom">
                          <Button
                            disabled={disabled}
                            onClick={() => handleConfirmPlan()}
                            variant="primary"
                            block
                            className="mb-3"
                          >
                            Confirm
                          </Button>
                          <Button variant="outline-light" block>
                            Back
                          </Button>
                        </div>
                        <p>
                          By canceling a plan, you agree to the Recalc <br />
                          <Link to="/">Terms of Service</Link> and{" "}
                          <Link to="/">Privacy Policy</Link>.
                        </p>
                      </Card.Body>
                    </Card>
                  ) : billSettings &&
                    billSettings.payment_methods &&
                    billSettings.payment_methods.length > 0 ? (
                    <Card>
                      <Card.Body className="p-4">
                        <div className="plan-header">
                          <Badge variant="success">New plan</Badge>
                          <h4>{rowPlan.name}</h4>
                          <div className="d-flex justify-content-between">
                            <div className="price">
                              {rowPlan.amount.toFixed(2)}{" "}
                              <span className="text-success mr-1">$</span>
                              <small>
                                for{" "}
                                {rowPlan.frequency === "2" ? "year" : "month"}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="payment-plan-details">
                          <Form.Group>
                            <Form.Label>Member</Form.Label>
                            <div className="d-flex align-items-center justify-content-between">
                              <FormControl
                                type="number"
                                id="plan_members"
                                name="plan_members"
                                disabled
                                value={formInput.plan_members}
                                onChange={handleInput}
                              />
                              <div className="d-flex align-items-center">
                                <Button
                                  variant="outline-light"
                                  onClick={subtraction}
                                >
                                  -
                                </Button>
                                <div className="member-value">
                                  {formInput.plan_members}
                                </div>
                                <Button
                                  variant="outline-light"
                                  onClick={addition}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                        </div>

                        <Button
                          onClick={() => handleConfirmWithPaymentMethod()}
                          variant="primary"
                          block
                          className="mb-3"
                          disabled={confirmDisabled}
                        >
                          Confirm plan
                        </Button>
                        <p>
                          By canceling a plan, you agree to the Recalc <br />
                          <Link to="/">Terms of Service</Link> and{" "}
                          <Link to="/">Privacy Policy</Link>.
                        </p>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Body className="p-4">
                        <div className="plan-header">
                          <Badge variant="success">New plan</Badge>
                          <h4>{rowPlan.name}</h4>
                          <div className="d-flex justify-content-between">
                            <div className="price">
                              {rowPlan.amount.toFixed(2)}{" "}
                              <span className="text-success mr-1">$</span>
                              <small>
                                for{" "}
                                {rowPlan.frequency === "2" ? "year" : "month"}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="payment-plan-details">
                          <Form.Group className="border-bottom-0">
                            <Form.Label>Member 2</Form.Label>
                            <div className="d-flex align-items-center justify-content-between">
                              <FormControl
                                type="number"
                                id="plan_members"
                                name="plan_members"
                                disabled
                                value={formInput.plan_members}
                                onChange={handleInput}
                              />
                              <div className="d-flex align-items-center">
                                <Button
                                  variant="outline-light"
                                  onClick={subtraction}
                                >
                                  -
                                </Button>
                                <div className="member-value">
                                  {formInput.plan_members}
                                </div>
                                <Button
                                  variant="outline-light"
                                  onClick={addition}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="stripe-card-trial-info">
                          <Elements stripe={stripePromise}>
                            <CheckoutForm
                              verifyCard={verifyCard}
                              buttonText="Pay Invoice"
                              disabled={disabled}
                              setDisabled={setDisabled}
                              setCardInfo={setCardInfo}
                            />
                          </Elements>
                        </div>
                        <p>
                          By canceling a plan, you agree to the Recalc <br />
                          <Link to="/">Terms of Service</Link> and{" "}
                          <Link to="/">Privacy Policy</Link>.
                        </p>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>{" "}
          </Container>
        );
      default:
        return (
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={11}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h2 className="title">{title}</h2>
                  <div className="toggle-switch">
                    <div className="text pr-4">Monthly / Yearly</div>
                    <label className="switch m-0">
                      <input
                        type="checkbox"
                        onChange={handleChangePlansType}
                        defaultChecked={false}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={11}>{plans_list}</Col>
            </Row>
          </Container>
        );
    }
  }
  return (
    <div className="change-plan-page">
      {getTabContent()} <AfterLogin />{" "}
    </div>
  );
}

export default ChangePlan;
