import {
  roles,
  states,
  cities,
  settings,
  company_update,
  name_update,
  password_update,
  verify_email_update,
  email_update,
  organization_settings,
  organization_settings_suta,
  organization_settings_import_suta,
  organization_settings_tax_status,
  organization_settings_sales_tax,
  organization_settings_import_wc,
  organization_settings_update_spto,
  organization_settings_wc_settings,
  organization_settings_always_accrue,
  organization_settings_package_delete,
  organization_settings_cost_delete,
  organization_settings_package,
  organization_settings_update_cost,
  industry,
  organization_spto_settings,
  organization_teams,
  organization_team_update,
  organization_team_save,
  plans,
  subscriptionUpdate,
  bill_settings,
  payment_method_update,
  payment_method_add,
  payment_method_delete,
  payment_method_set_default,
  billing_info,
  update_billing_info,
  subscription,
  subscriptionCancel,
  subscriptionAddPlan,
  set_profile_image,
  set_company_image,
  set_team_logo
} from "../../core/Service";

export const getRolesAttempt = async (payload) => {
  const response = await roles(payload);
  if (response.data) return response.data;
  else return [];
};

export const getPlans = async (payload) => {
  const response = await plans(payload);
  if (response.data) return response.data;
  else return [];
};
export const getIndustriesAttempt = async (payload) => {
  const response = await industry(payload);
  if (response.data) return response.data;
  else return [];
};
export const getStatesAttempt = async (payload) => {
  const response = await states(payload);
  if (response.data) return response.data;
  else return [];
};
export const getCitiesAttempt = async (payload) => {
  const response = await cities(payload);
  if (response.data) return response.data;
  else return [];
};
export const getSettings = async (payload) => {
  const response = await settings(payload);
  if (response.data) return response.data;
  else return [];
};

export const simpleSubscription = async (payload) => {
  const response = await subscription(payload);
  return response;
};
export const updateSubscription = async (payload) => {
  const response = await subscriptionUpdate(payload);
  return response;
};
export const cancelSubscription = async (payload) => {
  const response = await subscriptionCancel(payload);
  return response;
};
export const createSubscription = async (payload) => {
  const response = await subscriptionAddPlan(payload);
  return response;
};
export const updateCompanyAttempt = async (payload) => {
  const response = await company_update(payload);
  return response;
};
export const updateNameAttempt = async (payload) => {
  const response = await name_update(payload);
  return response;
};
export const updatePasswordAttempt = async (payload) => {
  const response = await password_update(payload);
  return response;
};
export const verifyEmailAttempt = async (payload) => {
  const response = await verify_email_update(payload);
  return response;
};
export const updateEmailAttempt = async (payload) => {
  const response = await email_update(payload);
  return response;
};

export const addPaymentMethod = async (payload) => {
  const response = await payment_method_add(payload);
  return response;
};
export const setDefaultPaymentMethod = async (payload) => {
  const response = await payment_method_set_default(payload);
  return response;
};
export const deletePaymentMethod = async (payload) => {
  const response = await payment_method_delete(payload);
  return response;
};
export const getOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings(payload);
  return response;
};
export const sutaOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_suta(payload);
  return response;
};
export const importSutaOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_import_suta(payload);
  return response;
};
export const taxStatusOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_tax_status(payload);
  return response;
};
export const salesTaxOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_sales_tax(payload);
  return response;
};
export const importWcOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_import_wc(payload);
  return response;
};
export const updateSptoOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_update_spto(payload);
  return response;
};
export const wcSettingsOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_wc_settings(payload);
  return response;
};
export const alwaysAccureOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_always_accrue(payload);
  return response;
};
export const packageDeleteOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_package_delete(payload);
  return response;
};
export const costDeleteOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_cost_delete(payload);
  return response;
};
export const packageOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_package(payload);
  return response;
};
export const updateCostOrganizationSettingAttempt = async (payload) => {
  const response = await organization_settings_update_cost(payload);
  return response;
};
export const saveOrganizationTeamAttempt = async (payload) => {
  const response = await organization_team_save(payload);
  return response;
};
export const updateOrganizationTeamAttempt = async (payload) => {
  const response = await organization_team_update(payload);
  return response;
};
export const getSPTOSettings = async (payload) => {
  const response = await organization_spto_settings(payload);
  return response.data;
};
export const getTeams = async (payload) => {
  const response = await organization_teams(payload);
  return response;
};
export const getBillSettings = async (payload) => {
  const response = await bill_settings(payload);
  return response.data;
};

export const getBillingInfo = async (payload) => {
  const response = await billing_info(payload);
  return response.data;
};

export const updateBillingInfo = async (payload) => {
  const response = await update_billing_info(payload);
  return response;
};
export const setProfileImage = async (payload) => {
  const response = await set_profile_image(payload);
  return response;
};
export const setCompanyImage = async (payload) => {
  const response = await set_company_image(payload);
  return response;
};
export const setTeamLogo = async (payload) => {
  const response = await set_team_logo(payload);
  return response;
};
