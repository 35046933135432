import React, { useReducer, useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Modal,
  Form,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import BadgeIcon from "../component/assets/images/icons/badge.svg";
import CalculatorIcon from "../component/assets/images/icons/calculator.svg";
import { connect } from "react-redux";
import * as Constants from "../core/Constants";
import {
  getSettings,
  getStatesAttempt,
  getCitiesAttempt,
  updateCompanyAttempt,
  getBillSettings,
  setCompanyImage,
} from "./data/globalAction";
import SimpleReactValidator from "simple-react-validator";
import UserImage from "./assets/images/user.png";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Moment from "react-moment";
import AfterLogin from "./middleware/AfterLogin";
import { isAdmin, isOwner } from "../core/UserRoleCheck";

const customControlStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 50,
    marginBottom: "15px",
    background: state.isFocused ? "#fff" : "#fff",
    borderWidth: 1,
    borderRadius: "8px",
    borderColor: state.isFocused ? "#7848FF" : "#E5E8F5",
    boxShadow: state.isFocused ? null : null,
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#7848FF" : null,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "13px",
    border: "1px solid #f1f3f6",
    boxShadow: "none",
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? "#f1f3f6" : "#fff",
    color: "#151B26",
    "&:hover": {
      background: "#f1f3f6",
      color: "#151B26",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A8B9CD",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

function Settings(props) {
  const [formInputCompany, setFormInputCompany] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      company_id: "",
      company_name: "",
      country: Constants.default_county.value,
      state: "",
      city: "",
      address: "",
      employees: "",
      zip_code: "",
    }
  );
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [settings, setSettings] = useState([]);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const [billSettings, setBillSettings] = useState([]);

  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const [validator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  useEffect(() => {
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("expire")) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  });
  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  }, [settings]);

  var trailDays = 7;
  if (settings.data && settings.data.created_at) {
    var date1 = new Date(settings.data.created_at);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    trailDays = trailDays - Math.round(Difference_In_Days);
  }

  const handleInputCompany = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInputCompany({ [name]: newValue });
  };
  const handleSelectCompany = async (info, selectName) => {
    const name = selectName;
    const newValue = info.value;
    setFormInputCompany({ [name]: newValue });
    if (selectName === "state") {
      setCities(await getCitiesAttempt(newValue));
    }
  };

  const handleCompanySave = async () => {
    console.log("here", formInputCompany);
    setDisabled(true);
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(1);
      setDisabled(false);
    } else {
      let result = await updateCompanyAttempt(JSON.stringify(formInputCompany));
      if (result.status === 200) {
        setSettings(result.data);
        setShow(false);
        toast.success(Constants.update_message);
      } else {
        setDisabled(false);
      }

      setDisabled(false);
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isAdmin || isOwner) {
      if (
        settings.data &&
        settings.data.organization_users &&
        settings.data.organization_users.length !== 0 &&
        settings.data.organization_users[0].organization
      ) {
        let organization = settings.data.organization_users[0].organization;

        setFormInputCompany({ ["company_id"]: organization.id });

        setFormInputCompany({ ["company_name"]: organization.name });

        setFormInputCompany({ ["state"]: organization.state_id });

        async function fetchStates() {
          setStates(await getStatesAttempt(Constants.default_county.value));
        }
        fetchStates();

        setFormInputCompany({ ["city"]: organization.city_id });

        if (organization.city_id !== "" && cities !== "") {
          async function fetchCities() {
            setCities(await getCitiesAttempt(organization.state_id));
          }
          fetchCities();
        }

        setFormInputCompany({ ["employees"]: organization.employees });

        setFormInputCompany({ ["address"]: organization.address });

        setFormInputCompany({ ["zip_code"]: organization.zip });
      }
      setShow(true);
    } else {
      toast.error(Constants.unauthorized);
    }
  };

  let statesOptions = [];
  let stateDisabe = true;
  let stateSelected = "";
  if (states && states.length !== 0) {
    {
      states &&
        states.map((state) => {
          let row = { label: state.name, value: state.id };
          if (state.id === formInputCompany.state) {
            stateSelected = row;
          }
          statesOptions.push(row);
        });
    }
    stateDisabe = false;
  }
  let citiesOptions = [];
  let citiesDisabe = true;
  let citiesSelected = "";
  if (cities && cities.length !== 0) {
    {
      cities &&
        cities.map((city) => {
          let row = { label: city.name, value: city.id };
          if (city.id === formInputCompany.city) {
            citiesSelected = row;
          }
          citiesOptions.push(row);
        });
    }
    citiesDisabe = false;
  }
  let employeeSelected = "";
  Constants.employees_options.forEach((element) => {
    if (element.value === formInputCompany.employees) {
      employeeSelected = element;
    }
  });

  const handleCompanyImage = async (e) => {
    let formImage = new FormData();
    formImage.append("image", e.target.files[0]);

    let result = await setCompanyImage(formImage);
    if (result.status === 200) {
      async function fetchSettings() {
        setSettings(await getSettings());
      }
      await fetchSettings();
      toast.success(Constants.update_message);
    }
  };
  return (
    <div className="settings-page">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <h2 className="title">Settings</h2>
            <Row>
              {isAdmin || isOwner ? (
                <Col lg={6}>
                  <Link to="/settings/billing-payment">
                    <Card className="billing-section">
                      <div className="card-image-holder">
                        <Card.Img variant="top" src={BadgeIcon} alt="Icon" />
                      </div>
                      <Card.Body>
                        <Card.Title>
                          Billing <br /> and payment
                        </Card.Title>
                        <Card.Text>
                          {settings.data &&
                          settings.data.is_enterprise === "1" ? (
                            <strong>Enterprise</strong>
                          ) : (
                            <strong>Single</strong>
                          )}
                          {billSettings && billSettings.subscription ? (
                            <div>
                              Plan is valid till{" "}
                              <Moment format="MMMM DD, YYYY">
                                {billSettings.subscription.end_date}
                              </Moment>
                            </div>
                          ) : trailDays > -1 ? (
                            <p className="m-0">
                              {trailDays}{" "}
                              {Constants.settings_billing_trail_days}
                            </p>
                          ) : (
                            <p className="m-0">
                              {Constants.settings_billing_trail_days_expire}
                            </p>
                          )}
                          {}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ) : null}
              {isAdmin || isOwner ? (
                <Col lg={6}>
                  <Link to="/settings/calculation-settings">
                    <Card className="calculation-section">
                      <div className="card-image-holder">
                        <Card.Img
                          variant="top"
                          src={CalculatorIcon}
                          alt="Icon"
                        />
                      </div>
                      <Card.Body>
                        <Card.Title>
                          Calculation <br /> Settings
                        </Card.Title>
                        <Card.Text></Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ) : null}
              {isAdmin || isOwner ? (
                <Col lg={6}>
                  {settings.data &&
                  settings.data.organization_users &&
                  settings.data.organization_users.length !== 0 &&
                  settings.data.organization_users[0].organization &&
                  settings.data.organization_users[0].organization.name ? (
                    <div>
                      <Link onClick={handleShow}>
                        <Card className="mt-4">
                          {settings && settings.data.organization_users[0].organization.image ? (
                            <div className="user-pic">
                              <img src={settings.data.organization_users[0].organization.image} alt="Image" />
                            </div>
                          ) : (
                            <div className="name-char">
                              {settings.data.organization_users[0].organization.name
                              .charAt(0)
                              .toUpperCase()}
                            </div>
                          )}
                          <Card.Body>
                            <Card.Title>
                              Company <br /> information
                            </Card.Title>
                            <Card.Text>
                              <strong>
                                {
                                  settings.data.organization_users[0]
                                    .organization.name
                                }
                              </strong>
                              <p className="m-0">
                                {
                                  settings.data.organization_users[0]
                                    .organization.address
                                }
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Link>
                      <Modal
                        animation={false}
                        className="company-settings-modal"
                        centered
                        size="sm"
                        show={show}
                        onHide={handleClose}
                      >
                        <Modal.Header></Modal.Header>
                        <Modal.Body>
                          <div className="modal-head">
                            <label htmlFor="company_image">
                              {settings.data.organization_users[0].organization
                                .image ? (
                                <div className="user-pic">
                                  <img
                                    src={
                                      settings.data.organization_users[0]
                                        .organization.image
                                    }
                                    alt="Image"
                                  />
                                </div>
                              ) : (
                                <div className="name-char">
                                  {settings.data.organization_users[0].organization.name
                                    .charAt(0)
                                    .toUpperCase()}
                                </div>
                              )}
                            </label>
                            <input
                              type="file"
                              id="company_image"
                              style={{ display: "none" }}
                              onChange={handleCompanyImage}
                            />
                            <div className="title">
                              {
                                settings.data.organization_users[0].organization
                                  .name
                              }
                            </div>
                          </div>
                          <form className="form">
                            <Form.Group>
                              <Form.Label>Company Name</Form.Label>
                              <FormControl
                                required
                                id="company_name"
                                value={formInputCompany.company_name}
                                name="company_name"
                                onChange={handleInputCompany}
                              />
                              {validator.message(
                                "company_name",
                                formInputCompany.company_name,
                                "required",
                                { className: "text-danger" }
                              )}
                            </Form.Group>
                            <Row>
                              <Col lg={6}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    State
                                  </Form.Label>
                                  <Select
                                    name="state"
                                    placeholder="Alaska"
                                    value={stateSelected}
                                    onChange={(value) => {
                                      handleSelectCompany(value, "state");
                                    }}
                                    options={statesOptions}
                                    isDisabled={stateDisabe}
                                    styles={customControlStyles}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    City
                                  </Form.Label>
                                  <Select
                                    name="city"
                                    placeholder="Chevak"
                                    value={citiesSelected}
                                    onChange={(value) => {
                                      handleSelectCompany(value, "city");
                                    }}
                                    options={citiesOptions}
                                    isDisabled={citiesDisabe}
                                    styles={customControlStyles}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={8}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    Company Address
                                  </Form.Label>
                                  <FormControl
                                    required
                                    value={formInputCompany.address}
                                    name="address"
                                    type="text"
                                    id="address"
                                    placeholder="33 3rd Ave, New York, NY 10003, USA"
                                    onChange={handleInputCompany}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4}>
                                <Form.Group>
                                  <Form.Label className="optional">
                                    Zip Code
                                  </Form.Label>
                                  <FormControl
                                    required
                                    value={formInputCompany.zip_code}
                                    name="zip_code"
                                    placeholder="10017"
                                    type="text"
                                    id="zip_code"
                                    onChange={handleInputCompany}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group>
                              <Form.Label className="optional">
                                No of employees
                              </Form.Label>
                              <Select
                                name="employees"
                                menuPlacement="top"
                                placeholder="More than 50"
                                value={employeeSelected}
                                onChange={(value) => {
                                  handleSelectCompany(value, "employees");
                                }}
                                options={Constants.employees_options}
                                styles={customControlStyles}
                              />
                            </Form.Group>
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="outline-light" onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            disabled={disabled}
                            onClick={handleCompanySave}
                          >
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  ) : null}
                </Col>
              ) : null}
              <Col lg={6}>
                {settings.data && settings.data.first_name ? (
                  <Link to="/settings/account-security">
                    <Card className="mt-4">
                      {settings.data.image ? (
                        <div className="user-pic">
                          <img src={settings.data.image} alt="Image" />
                        </div>
                      ) : (
                        <div className="name-char">
                          {settings.data.first_name.charAt(0).toUpperCase() +
                            settings.data.last_name.charAt(0).toUpperCase()}
                        </div>
                      )}
                      <Card.Body>
                        <Card.Title>
                          Account <br /> and Security
                        </Card.Title>
                        <Card.Text>
                          <strong>
                            {settings.data.first_name} {settings.data.last_name}
                          </strong>
                          <p className="m-0">{settings.data.email}</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
      <AfterLogin />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
