import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./component/assets/scss/main.scss";
import SignIn from "./component/SignIn";
import SignUp from "./component/SignUp";
import SignOut from "./component/SignOut";
import Dashboard from "./component/Dashboard";
import ForgotPassword from "./component/ForgotPassword";
import ResetPassword from "./component/ResetPassword";
import Homepage from "./component/Homepage";
import Settings from "./component/Settings";

import Navigation from "./component/layout/Navigation";

import axios from "axios";
import AccountSecurity from "./component/AccountSecurity";
import CalculationSettings from "./component/CalculationSettings";
import BillingPayment from "./component/BillingPayment";
import ChangePlan from "./component/ChangePlan";
import PaymentMethod from "./component/PaymentMethod";
import BillingInformation from "./component/BillingInformation";
import Company from "./component/Company";
import AdminPrivateRoute from './privateRoutes/adminPrivateRoute'

function App(props) {
  const history = useHistory();
  const checkDomain = () => {
    axios.defaults.headers = {
      "content-type": "application/json",
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/check_domain", {
        domain: window.location.host,
      })
      .then(function (response) {
        if (response.data.status === "ignore") {
          if (props.login && props.login.length !== 0) {
            window.location.href =
              process.env.REACT_APP_SUBDOMIAN_URL.replace(
                "{url}",
                props.login.data.organization_users[0].organization
                  .business_domain
              ) + "/dashboard";
          }
        } else if (response.data.status === "success") {
          if (props.login && props.login.length !== 0) {
            window.location.href =
              process.env.REACT_APP_SUBDOMIAN_URL.replace(
                "{url}",
                props.login.data.organization_users[0].organization
                  .business_domain
              ) + "/dashboard";
          }
        } else {
          window.location.href = process.env.REACT_APP_URL;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/signup" &&
    window.location.pathname !== "/forgot-password" &&
    window.location.pathname !== "/reset-password" &&
    window.location.pathname !== "/signin"
  ) {
    if (props.login && props.login.length === 0) {
      history.push("/signin");
    }
  } else {
    checkDomain();
    console.log(1234);
  }

  return (
    <div>
      <Loader
        type="Oval"
        className="loader-main"
        color="#000"
        height={50}
        width={50}
        timeout={2000} //2 secs
      />
      <Navigation />
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <Switch>
        <Route path="/company">
          <Company />
        </Route>
        <AdminPrivateRoute path="/settings/billing-payment/billing-information"><BillingInformation /></AdminPrivateRoute>
        <AdminPrivateRoute path="/settings/billing-payment/payment-method"><PaymentMethod /></AdminPrivateRoute>
        <AdminPrivateRoute path="/settings/billing-payment"><BillingPayment /></AdminPrivateRoute>
        <AdminPrivateRoute path="/change-plan"><ChangePlan /></AdminPrivateRoute>
        <AdminPrivateRoute path="/settings/calculation-settings"><CalculationSettings /></AdminPrivateRoute>
        <Route path="/settings/account-security"><AccountSecurity /></Route>

        <Route path="/settings"><Settings /></Route>

        <Route path="/dashboard">
          <Dashboard />
        </Route>

        <Route path="/logout">
          <SignOut />
        </Route>

        <Route path="/reset-password">
          <ResetPassword />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/signin">
          <SignIn />
        </Route>

        <Route path="/signup">
          <SignUp />
        </Route>

        <Route path="/">
          <Homepage />
        </Route>
      </Switch>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
