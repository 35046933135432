import React, { useReducer, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Col,
  Row,
  Modal,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import UserIcon from "../component/assets/images/icons/user2.svg";
import EmailIcon from "../component/assets/images/icons/email.svg";
import LockIcon from "../component/assets/images/icons/lock.svg";
import AccountModalImage from "../component/assets/images/account-security.png";
import chevLeft from "../component/assets/images/icons/cheveron-left.svg";
import UserImage from "./assets/images/user.png";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { signInAttemptResponse } from "./data/loginAction";

import {
  getSettings,
  updateNameAttempt,
  updatePasswordAttempt,
  verifyEmailAttempt,
  updateEmailAttempt,
  setProfileImage,
} from "./data/globalAction";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import * as Constants from "../core/Constants";
import Payment from "./Payment";
import AfterLogin from "./middleware/AfterLogin";

function AccountSecurity(props) {
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState([]);
  const [validator] = useState(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isPaymentShowing, setIsPaymentShowing] = useState(false);
  const handleClosePayment = () => {
    localStorage.removeItem("expire");
    setIsPaymentShowing(false);
  };
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      first_name: "",
      last_name: "",
      email: "",
      old_password: "",
      password: "",
      password_confirmation: "",
      otp_code: "",
    }
  );
  const handleClose = () => setShow(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setDisabled(true);

    if (step === "change_name") {
      if (
        validator.fieldValid("first_name") &&
        validator.fieldValid("last_name")
      ) {
        let data = {
          first_name: formInput.first_name,
          last_name: formInput.last_name,
        };
        let result = await updateNameAttempt(JSON.stringify(data));

        if (result.status === 200) {
          setSettings(result.data);
          props.signInAttemptResponse(result);
          setShow(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
          setDisabled(false);
        }
      } else {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      }
    } else if (step === "change_password") {
      if (
        validator.fieldValid("old_password") &&
        validator.fieldValid("password") &&
        validator.fieldValid("password_confirmation")
      ) {
        let data = {
          old_password: formInput.old_password,
          password: formInput.password,
          password_confirmation: formInput.password_confirmation,
        };
        let result = await updatePasswordAttempt(JSON.stringify(data));
        if (result.status === 200) {
          setSettings(result.data);
          setShow(false);
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
          setDisabled(false);
        }
      } else {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      }
    } else if (step === "change_email") {
      if (validator.fieldValid("email")) {
        let data = {
          email: formInput.email,
        };
        let result = await verifyEmailAttempt(JSON.stringify(data));
        if (result.status === 200) {
          setSettings(result.data);
          setStep("verify_email");
          toast.success(Constants.update_message);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
          setDisabled(false);
        }
      } else {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      }
    } else if (step === "verify_email") {
      if (validator.fieldValid("otp_code")) {
        let data = {
          email: formInput.email,
          otp_code: formInput.otp_code,
        };
        let result = await updateEmailAttempt(JSON.stringify(data));
        if (result.status === 200) {
          toast.success(Constants.logout_message);
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_URL + "/logout";
          }, 2000);
        } else {
          if (localStorage.getItem("expire")) {
            setIsPaymentShowing(true);
            console.log("expire", localStorage.getItem("expire"));
          }
          setDisabled(false);
        }
      } else {
        validator.showMessages();
        forceUpdate(1);
        setDisabled(false);
      }
    }

    setDisabled(false);
    //   setShow(false);
  };
  const [step, setStep] = useState("change_name");
  useEffect(() => {
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("expire")) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  });
  useEffect(() => {
    if (settings.expire && settings.expire === true) {
      setIsPaymentShowing(true);
      console.log("expire", localStorage.getItem("expire"));
    }
  }, [settings]);

  const handleShow = async (evt, index) => {
    setStep(index);
    if (settings.data && settings.data.first_name) {
      let user = settings.data;
      setFormInput({ ["first_name"]: user.first_name });
      setFormInput({ ["last_name"]: user.last_name });
      setFormInput({ ["email"]: "" });
      setFormInput({ ["old_password"]: "" });
      setFormInput({ ["password"]: "" });
      setFormInput({ ["password_confirmation"]: "" });
      setFormInput({ ["otp_code"]: "" });
    }

    setShow(true);
  };

  const handleUserImage = async (e) => {
    let formImage = new FormData();
    formImage.append("image", e.target.files[0]);
    console.log("formImage", formImage);
    let result = await setProfileImage(formImage);
    if (result.status === 200) {
      setSettings(result.data);

      props.signInAttemptResponse(result);
      setShow(false);
      toast.success(Constants.update_message);
    }
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };
  function getStepContent() {
    switch (step) {
      case "change_name":
        return (
          <div className="details">
            <div className="icon">
              <img src={AccountModalImage} alt="Big Image" />
            </div>

            <div className="title">Change name</div>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <FormControl
                id="first_name"
                name="first_name"
                value={formInput.first_name}
                onChange={handleInput}
              />
              {validator.message(
                "first_name",
                formInput.first_name,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <FormControl
                id="last_name"
                name="last_name"
                value={formInput.last_name}
                onChange={handleInput}
              />
              {validator.message("last_name", formInput.last_name, "required", {
                className: "text-danger",
              })}
            </Form.Group>
          </div>
        );
      case "change_email":
        return (
          <div className="details">
            <div className="icon">
              <img src={AccountModalImage} alt="Big Image" />
            </div>
            <div className="title">Change your email address</div>
            <p>
              Current email address: <span>{settings.data.email}</span>
            </p>
            <Form.Group>
              <Form.Label>Entry new email address</Form.Label>
              <FormControl
                id="email"
                name="email"
                value={formInput.email}
                onChange={handleInput}
              />
              {validator.message("email", formInput.email, "required|email", {
                className: "text-danger",
              })}
            </Form.Group>
          </div>
        );
      case "verify_email":
        return (
          <div className="details">
            <div className="icon">
              <img src={AccountModalImage} alt="Big Image" />
            </div>
            <div className="title">Verify your Email</div>
            <p>
              <span>{formInput.email}</span>
              <Link
                className="btn btn-light"
                onClick={(e) => setStep("change_email")}
              >
                Change
              </Link>
            </p>
            <p className="mb-4">
              We've sent a One Time Password (OTP) <br />
              to your email address. Please enter it below.
            </p>
            <Form.Group>
              <Form.Label>Enter OTP</Form.Label>
              <FormControl
                id="otp_code"
                name="otp_code"
                value={formInput.otp_code}
                onChange={handleInput}
              />
              {validator.message("otp_code", formInput.otp_code, "required", {
                className: "text-danger",
              })}
            </Form.Group>
          </div>
        );
      case "change_password":
        return (
          <div className="details">
            <div className="icon">
              <img src={AccountModalImage} alt="Big Image" />
            </div>
            <div className="title">Change password</div>
            <Form.Group>
              <Form.Label>Current Password</Form.Label>
              <FormControl
                type="password"
                id="old_password"
                name="old_password"
                value={formInput.old_password}
                onChange={handleInput}
              />
              {validator.message(
                "old_password",
                formInput.old_password,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <FormControl
                type="password"
                id="password"
                name="password"
                value={formInput.password}
                onChange={handleInput}
              />
              {validator.message("password", formInput.password, "required", {
                className: "text-danger",
              })}
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm new password</Form.Label>
              <FormControl
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                value={formInput.password_confirmation}
                onChange={handleInput}
              />
              {validator.message(
                "password_confirmation",
                formInput.password_confirmation,
                "required",
                {
                  className: "text-danger",
                }
              )}
            </Form.Group>
          </div>
        );
      default:
        return "";
    }
  }
  let content = getStepContent();
  return (
    <div className="account-security-page">
      <div className="grey-box">
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={10}>
              <h2 className="title">
                <Link to="/settings">
                  <img src={chevLeft} alt="icon" />
                </Link>
                Account and Security
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      {settings.data && settings.data.first_name ? (
        <div>
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={10}>
                <div className="details">
                  <div class="personal-info">
                    <label htmlFor="profile_image">
                      {settings.data.image ? (
                        <div className="user-pic">
                          <img src={settings.data.image} alt="Image" />
                        </div>
                      ) : (
                        <>
                          <div class="name-spell">
                            {settings.data.first_name.charAt(0).toUpperCase() +
                              settings.data.last_name.charAt(0).toUpperCase()}
                          </div>
                        </>
                      )}
                    </label>
                    <input
                      type="file"
                      id="profile_image"
                      style={{ display: "none" }}
                      onChange={handleUserImage}
                    />
                    <div class="text-details">
                      <strong>
                        {settings.data.first_name} {settings.data.last_name}
                      </strong>
                      <p>{settings.data.email}</p>
                    </div>
                  </div>
                  <div className="other-info">
                    <div className="list">
                      <div className="d-flex justify-content-between box">
                        <div className="d-flex align-items-center">
                          <div className="icon">
                            <img src={UserIcon} alt="Icon Image" />
                          </div>
                          <div className="label">Name</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="title">
                            {settings.data.first_name} {settings.data.last_name}
                          </div>
                          <Link onClick={(e) => handleShow(e, "change_name")}>
                            Change
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="list">
                      <div className="d-flex justify-content-between box">
                        <div className="d-flex align-items-center">
                          <div className="icon">
                            <img src={EmailIcon} alt="Icon Image" />
                          </div>
                          <div className="label">Email</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="title">{settings.data.email}</div>
                          <Link onClick={(e) => handleShow(e, "change_email")}>
                            Change
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="list">
                      <div className="d-flex justify-content-between box">
                        <div className="d-flex align-items-center">
                          <div className="icon lock-icon">
                            <img src={LockIcon} alt="Icon Image" />
                          </div>
                          <div className="label">Password</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="title">************</div>
                          <Link
                            onClick={(e) => handleShow(e, "change_password")}
                          >
                            Change
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="social-title">Social media accounts</div>
                  <div className="other-info">
                    <div className="list">
                      <div className="d-flex justify-content-between box">
                        <div className="d-flex align-items-center">
                          <div className="icon">
                            <FcGoogle />
                          </div>
                          <div className="label">Google</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="title">not connected</div>
                          <a href="#">Connect</a>
                        </div>
                      </div>
                    </div>
                    <div className="list">
                      <div className="d-flex justify-content-between box">
                        <div className="d-flex align-items-center">
                          <div className="icon">
                            <FaFacebook />
                          </div>
                          <div className="label">Facebook</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="title">not connected</div>
                          <a href="#">Connect</a>
                        </div>
                      </div>
                    </div>
                    <div className="list">
                      <div className="d-flex justify-content-between box">
                        <div className="d-flex align-items-center">
                          <div className="icon">
                            <FaLinkedin />
                          </div>
                          <div className="label">Linkedin</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="title">not connected</div>
                          <a href="#">Connect</a>
                        </div>
                      </div>
                    </div>
                  </div>
                 */}
                </div>
              </Col>
            </Row>
          </Container>
          <Modal
            animation={false}
            className="account-settings-modal"
            size="sm"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={disabled}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}
      {isPaymentShowing ? (
        <Payment onClose={handleClosePayment} isShowing={isPaymentShowing} />
      ) : null}
      <AfterLogin />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    signInAttemptResponse: (data) => dispatch(signInAttemptResponse(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    login: state.login.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSecurity);
