import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import chevLeft from "../component/assets/images/icons/cheveron-left.svg";
import { NavLink } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import { BsPlus } from "react-icons/bs";
import Select from "react-select";
import TagsInput from "react-tagsinput";

import visa from "./assets/images/cards/visa.png";
import americanexpress from "./assets/images/cards/americanexpress.png";
import amex from "./assets/images/cards/amex.png";
import diners from "./assets/images/cards/diners.png";
import discover from "./assets/images/cards/discover.png";
import jcb from "./assets/images/cards/jcb.png";
import mastercard from "./assets/images/cards/mastercard.png";
import unionpay from "./assets/images/cards/unionpay.png";

import EditIcon from "./assets/images/icons/edit-pencil.svg";
import EditWhiteIcon from "./assets/images/icons/edit-pencil-white.svg";
import {
  getBillSettings,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  updateSubscription,
  cancelSubscription,
  getSettings
} from "./data/globalAction";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";
import * as Constants from "../core/Constants";
import Moment from "react-moment";
import AfterLogin from "./middleware/AfterLogin";

function BillingPayment() {
  const customRoleControlStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 30,
      minWidth: 100,
      background: state.isFocused ? "transparent" : "transparent",
      borderWidth: 1,
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: state.isFocused ? null : null,
      fontSize: "12px",
      "&:hover": {
        borderColor: state.isFocused ? null : null,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (base) => ({
      ...base,
      fontSize: "13px",
      border: "1px solid #f1f3f6",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#f1f3f6" : "#fff",
      color: "#151B26",
      "&:hover": {
        background: "#f1f3f6",
        color: "#151B26",
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };
  const [showEditMember, setShowEditMember] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const handleShowMember = () => setShowEditMember(true);
  const handleCloseMember = () => setShowEditMember(false);
  const [billSettings, setBillSettings] = useState([]);
  const [billInfo, setbillInfo] = useState([]);

  const [settings, setSettings] = useState([]);

  const cancelPlan = async () => {
    let data = {
      plan_id: billSettings.plan.stripe_id,
      option: "cancel_plan",
    };

    let result = await cancelSubscription(JSON.stringify(data));
    if (result.status === 200) {
      toast.success(Constants.update_message);
      setRefresh(!refresh);
    }
  };

  const deletePaymentMehtod = async (pm_id) => {
    let data = {
      pm_id: pm_id,
    };
    let result = await deletePaymentMethod(JSON.stringify(data));
    if (result.status === 200) {
      toast.success(Constants.update_message);
      setRefresh(!refresh);
    }
  };

  const renderIcon = (card_type) => {
    switch (card_type) {
      case "visa":
        return visa;
      case "americanexpress":
        return americanexpress;
      case "amex":
        return amex;
      case "diners":
        return diners;
      case "discover":
        return discover;
      case "jcb":
        return jcb;
      case "mastercard":
        return mastercard;
      case "unionpay":
        return unionpay;
    }
  };

  const setDefaultPaymentConfirm = async (pm_id) => {
    let data = {
      pm_id: pm_id,
    };
    let result = await setDefaultPaymentMethod(JSON.stringify(data));
    if (result.status === 200) {
      setRefresh(!refresh);
      toast.success(Constants.update_message);
    }
  };
  const setDefaultPayment = async (pm_id) => {
    confirmAlert({
      title: "Are you sure",
      message: (
        <p className="mb-0">
          Are you sure to set payment method as default?
        </p>
      ),
      buttons: [
        {
          label: "Yes",
          className: "btn btn-primary",
          onClick: () => setDefaultPaymentConfirm(pm_id),
        },
        {
          label: "No",
          className: "btn btn-outline-light",
          onClick: () => {},
        },
      ],
    });
  };
  const handleDeletePaymentMehtod = (pm_id) => {
    confirmAlert({
      title: "Are you sure",
      message: (
        <p className="mb-0">Are you sure to delete payment method?</p>
      ),
      buttons: [
        {
          label: "Yes",
          className: "btn btn-primary",
          onClick: () => deletePaymentMehtod(pm_id),
        },
        {
          label: "No",
          className: "btn btn-outline-light",
          onClick: () => {},
        },
      ],
    });
  };
  const handleCancelPlan = () => {
    confirmAlert({
      title: "Are you sure",
      message: (
        <p className="mb-0">Are you sure to cancel the plan?</p>
      ),
      buttons: [
        {
          label: "Yes",
          className: "btn btn-primary",
          onClick: () => cancelPlan(),
        },
        {
          label: "No",
          className: "btn btn-outline-light",
          onClick: () => {},
        },
      ],
    });
  };
  useEffect(() => {
    async function fetchSettings() {
      setSettings(await getSettings());
    }
    fetchSettings();
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();
  }, []);
  useEffect(() => {
    async function fetchBillSettings() {
      setBillSettings(await getBillSettings());
    }
    fetchBillSettings();
  }, [refresh]);

  useEffect(() => {
    if (
      billSettings &&
      billSettings.subscription &&
      billSettings.subscription.stripe_object
    ) {
      setbillInfo(JSON.parse(billSettings.subscription.stripe_object));
      console.log(
        "stripe_object",
        JSON.parse(billSettings.subscription.stripe_object)
      );
    }
  }, [billSettings]);

  let payment_method_list = "";
  if (
    billSettings &&
    billSettings.payment_methods &&
    billSettings.payment_methods.length > 0
  ) {
    payment_method_list = billSettings.payment_methods.map((element, index) => {
      return (
        <div className="card-list">
          <div className="card-type mr-4">
            <img src={renderIcon(element.card_type)} alt={element.card_type} />
          </div>
          <div className="card-number mr-4">**** {element.card_last_four}</div>

          <div className="card-validity mr-4">
            Expires {element.card_expiry}
          </div>
          {element.status ? (
            <div className="payment-method mr-4">Default</div>
          ) : null}
          {element.status ? null : (
            <>
              <div className="custom-checkbox mr-4">
                <input
                  type="radio"
                  name="default_payment"
                  id={"default_payment_" + index}
                  onClick={() => setDefaultPayment(element.pm_id)}
                />
                <label for={"default_payment_" + index}>Set as Default</label>
              </div>
              <Button
                onClick={() => handleDeletePaymentMehtod(element.pm_id)}
                className="close-btn"
                variant="outline-dark"
              >
                <RiCloseLine />
              </Button>
            </>
          )}
        </div>
      );
    });
  }

  let billing_list = "";
  if (
    billSettings &&
    billSettings.billing_histories &&
    billSettings.billing_histories.length > 0
  ) {
    billing_list = billSettings.billing_histories.map((element) => {
      return (
        <tr>
          <td>
            <Moment format="DD MMMM, YYYY">{element.created_at}</Moment>
          </td>
          <td>{element.description}</td>
          <td>
            {element.current_price.toFixed(2)}{" "}
            <span className="text-success">$</span>
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="billing-payment-page">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <h2 className="title">
              <NavLink to="/settings">
                <img src={chevLeft} alt="icon" />
              </NavLink>
              Billing and payment
            </h2>
            <Card className="mb-4">
              <Card.Body className="p-0">
                <div className="main-details-box">
                  {billSettings && billSettings.plan && billSettings.plan.id ? (
                    <div className="box">
                      <div className="label">
                        {billSettings.plan.frequency === "2"
                          ? "Actual plan"
                          : "Monthly plan"}
                      </div>
                      <div className="value">{billSettings.plan.name}</div>
                      <div className="value">
                        {billSettings.plan.amount.toFixed(2)}
                        <span className="text-success"> $ </span>
                        <small>
                          for{" "}
                          {billSettings.plan.frequency === "2"
                            ? "year"
                            : "month"}{" "}
                        </small>
                      </div>
                    </div>
                  ) : (
                    <div className="box">
                      <div className="label"></div>
                      <div className="value">No Plan Active</div>
                      <div className="value"></div>
                    </div>
                  )}

                  <div className="box">
                    <div className="label">Monthly members</div>
                    <div className="value">8</div>
                    <Button
                      onClick={handleShowMember}
                      variant="outline-primary arrow"
                      className="mt-2"
                    >
                      Edit member
                    </Button>
                    <Modal
                      className="create-team-modal"
                      animation={false}
                      size="md"
                      centered
                      show={showEditMember}
                    >
                      <Modal.Header className="with-title">
                        <Modal.Title>Manage Team</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="pt-1 pb-3">
                        <div className="added-team-members pb-0">
                          <div className="added-members-title">4 members</div>
                          <div className="added-members-lists">
                            <div className="member-list d-flex justify-content-between">
                              <div className="member-name">
                                <div className="member-name-short">U</div>
                                <div>
                                  <strong>Username</strong>
                                  <span>mom@gma.com</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Select
                                  className="custommargin"
                                  styles={customRoleControlStyles}
                                />
                                <Button
                                  className="custommargin btn-icon"
                                  variant="secondary"
                                >
                                  <RiCloseLine />
                                </Button>
                              </div>
                            </div>
                            <div className="member-list d-flex justify-content-between">
                              <div className="member-name">
                                <div className="member-name-short">U</div>
                                <div>
                                  <strong>Username</strong>
                                  <span>mom@gma.com</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Select
                                  className="custommargin"
                                  styles={customRoleControlStyles}
                                />
                                <Button
                                  className="custommargin btn-icon"
                                  variant="secondary"
                                >
                                  <RiCloseLine />
                                </Button>
                              </div>
                            </div>
                            <div className="member-list d-flex justify-content-between">
                              <div className="member-name">
                                <div className="member-name-short">U</div>
                                <div>
                                  <strong>Username</strong>
                                  <span>mom@gma.com</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Select
                                  className="custommargin"
                                  styles={customRoleControlStyles}
                                />
                                <Button
                                  className="custommargin btn-icon"
                                  variant="secondary"
                                >
                                  <RiCloseLine />
                                </Button>
                              </div>
                            </div>
                            <div className="member-list d-flex justify-content-between">
                              <div className="member-name">
                                <div className="member-name-short">U</div>
                                <div>
                                  <strong>Username</strong>
                                  <span>mom@gma.com</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Select
                                  className="custommargin"
                                  styles={customRoleControlStyles}
                                />
                                <Button
                                  className="custommargin btn-icon"
                                  variant="secondary"
                                >
                                  <RiCloseLine />
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="total-payment">
                            <div>Total payment for members</div>
                            <strong>$60.00</strong>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="outline-light"
                          onClick={handleCloseMember}
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={handleCloseMember}>
                          Done
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="box">
                    <div className="label">Invoice total</div>
                    <div className="value">
                      150,00 <span className="text-success">$</span>
                    </div>
                  </div>
                  <div className="box">
                    {billSettings &&
                    billSettings.subscription &&
                    billSettings.subscription.cancel_at === null ? (
                      <NavLink
                        to="/change-plan"
                        as="button"
                        className="btn btn-primary btn-block"
                      >
                        {billSettings && billSettings.plan
                          ? "Change plan"
                          : "Choose plan"}
                      </NavLink>
                    ) : billSettings && billSettings.subscription === null ? (
                      <NavLink
                        to="/change-plan"
                        as="button"
                        className="btn btn-primary btn-block"
                      >
                        {billSettings &&
                        billSettings.plan &&
                        billSettings.plan.id > 0
                          ? "Change plan"
                          : "Choose plan"}
                      </NavLink>
                    ) : null}

                    {billSettings &&
                    billSettings.plan &&
                    billSettings.plan.id ? (
                      billSettings &&
                      billSettings.subscription &&
                      billSettings.subscription.cancel_at === null ? (
                        <Button
                          onClick={handleCancelPlan}
                          variant="outline-light"
                          block
                        >
                          Cancel plan
                        </Button>
                      ) : null
                    ) : null}
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="mb-4 payment-methods">
              <Card.Header>Payment method</Card.Header>
              <Card.Body className="pt-0">
                <div className="card-lists">{payment_method_list}</div>
                <NavLink
                  to="/settings/billing-payment/payment-method"
                  className="btn btn-light mt-3"
                >
                  <BsPlus /> Add payment method
                </NavLink>
              </Card.Body>
            </Card>

            <Card className="mb-4 billing-information">
              <Card.Header>Billing information</Card.Header>

              <Card.Body className="pt-0">
                {billSettings &&
                billSettings.payment_methods &&
                billSettings.payment_methods.length > 0 ? (
                  <NavLink
                    to="/settings/billing-payment/billing-information"
                    className="btn btn-outline-dark btn-update"
                  >
                    <div className="icon">
                      <img
                        src={EditIcon}
                        alt="Edit Icon"
                        className="icon-black"
                      />
                      <img
                        src={EditWhiteIcon}
                        alt="Edit Icon"
                        className="icon-white"
                      />
                    </div>
                    Update information
                  </NavLink>
                ) : (
                  <button
                    onClick={() =>
                      toast.error(Constants.err_billing_informeation)
                    }
                    className="btn btn-outline-dark btn-update"
                  >
                    <div className="icon">
                      <img
                        src={EditIcon}
                        alt="Edit Icon"
                        className="icon-black"
                      />
                      <img
                        src={EditWhiteIcon}
                        alt="Edit Icon"
                        className="icon-white"
                      />
                    </div>
                    Update information
                  </button>
                )}
              </Card.Body>
            </Card>

            <Card className="mb-4 billing-history">
              <Card.Header>Billing history</Card.Header>
              <Card.Body className="p-0">
                <Table className="m-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Client</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>{billing_list}</tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AfterLogin />
    </div>
  );
}

export default BillingPayment;
